/**
 * This module handles actions related to user registration and fetching city data.
 *
 * @module modules/userManagement
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {};

const actions = {
  /**
   * Registers a new user with the provided registration data.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User registration data, typically containing user details.
   * @returns {Promise} - A promise that resolves upon successful registration.
   */
  register(_, data) {
    return new Promise((resolve, reject) => {
      Axios.post(urlMapper.register, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Fetches a list of cities with optional parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @returns {Promise} - A promise that resolves with the city data.
   */
  cities() {
    return new Promise((resolve, reject) => {
      Axios.get(urlMapper.cities + "?limit=200&offset=0")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
