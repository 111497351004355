/**
 * This module handles actions related to fetching and managing ranking table data, including the ranking table list
 * and the count of ranking tables.
 *
 * @module modules/rankingTableManagement
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {
  rankingTableList: [],
  rankingTableListCount: 0,
};

const actions = {
  /**
   * Fetches a list of ranking tables with optional parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Optional parameters for fetching ranking tables.
   * @returns {Promise} - A promise that resolves with the ranking table data.
   */
  getRankingTableList({ commit }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.rankingsTable}`, { params })
        .then((response) => {
          commit("setData", {
            key: "rankingTableList",
            payload: response.data.results,
          });
          commit("setData", {
            key: "rankingTableListCount",
            payload: response.data.count,
          });
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

const mutations = {
  /**
   * Sets data in the state based on the provided payload.
   *
   * @param {Object} state - The Vuex state.
   * @param {Object} payload - An object containing the key and payload to update the state.
   */
  setData: (state, payload) => (state[payload.key] = payload.payload),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
