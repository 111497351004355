<script setup>
import { computed, onBeforeMount, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { NEXTMATCHES, RESULTS, GUESSES, ROUTE_NAME } from "@/utils/constants";

const route = useRoute();
const currentRouteName = computed(() => route.name);

const leftTitle = ref("");
const showLeftTitle = ref(true);

const rightTitle = ref("");
const showRightTitle = ref(true);

watch(
  () => route.name,
  () => {
    displayNavigationTitle();
  }
);
const displayNavigationTitle = () => {
  if (currentRouteName.value === ROUTE_NAME.resultsPage) {
    rightTitle.value = GUESSES;
    showLeftTitle.value = false;
  } else if (currentRouteName.value === ROUTE_NAME.nextMatchesPage) {
    showRightTitle.value = false;
    leftTitle.value = GUESSES;
  } else {
    showRightTitle.value = true;
    showLeftTitle.value = true;
    leftTitle.value = RESULTS;
    rightTitle.value = NEXTMATCHES;
  }
};

onBeforeMount(() => {
  displayNavigationTitle();
});
</script>
<template>
  <div class="header-pagination" id="header-pagination">
    <div class="container">
      <div class="header-pagination-row">
        <div class="header-pagination-left">
          <RouterLink
            v-if="showLeftTitle"
            :to="leftTitle === GUESSES ? '/' : '/results'"
          >
            <img src="@/assets/images/left-arrow.svg" alt="left-arrow" />
            <span>{{
              leftTitle === GUESSES
                ? $t("HomePage.guesses")
                : $t("HomePage.results")
            }}</span>
          </RouterLink>
        </div>
        <div class="header-pagination-right">
          <RouterLink
            v-if="showRightTitle"
            :to="rightTitle === GUESSES ? '/' : '/next-matches'"
          >
            <span>{{
              rightTitle === GUESSES
                ? $t("HomePage.guesses")
                : $t("HomePage.next_matches")
            }}</span>
            <img src="@/assets/images/right-arrow.svg" alt="left-arrow" />
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
