/**
 * This module handles user-related actions, including updating user information, fetching user details,
 * deleting user profiles, user logout, and changing passwords.
 *
 * @module modules/userManagement
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";
import { removeLocalStorageItem } from "@/utils/localStorage";

const state = {
  userDetails: {},
};

const actions = {
  /**
   * Updates user information with the provided data.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User data to be updated, such as profile details.
   * @returns {Promise} - A promise that resolves upon successful user information update.
   */
  updateUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      Axios.patch(urlMapper.userProfile, data)
        .then((response) => {
          commit("setData", { key: "userDetails", payload: response.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Fetches user details from the server.
   *
   * @param {Object} context - The Vuex store context.
   * @returns {Promise} - A promise that resolves with the user details data.
   */
  getUserDetails({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get(urlMapper.userProfile)
        .then((response) => {
          commit("setData", { key: "userDetails", payload: response.data });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Deletes the user's profile.
   *
   * @returns {Promise} - A promise that resolves upon successful profile deletion.
   */
  deleteProfile() {
    return new Promise((resolve, reject) => {
      Axios.delete(urlMapper.userProfile)
        .then((response) => {
          removeLocalStorageItem("Authtoken");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Logs the user out and removes the authentication token from local storage.
   *
   * @returns {Promise} - A promise that resolves upon successful user logout.
   */
  logout() {
    return new Promise((resolve, reject) => {
      Axios.post(urlMapper.logout)
        .then((response) => {
          removeLocalStorageItem("Authtoken");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Changes the user's password with the provided parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Parameters for changing the user's password.
   * @returns {Promise} - A promise that resolves upon successful password change.
   */
  changePassword(_, params) {
    return new Promise((resolve, reject) => {
      Axios.put(urlMapper.changepassword, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setData: (state, payload) => (state[payload.key] = payload.payload),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
