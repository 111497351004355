/**
 * This module handles user authentication-related actions, such as verifying tokens, login, forget password,
 * and resetting the password.
 *
 * @module modules/authentication
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";
import { addLocalStorageItem } from "@/utils/localStorage";

const state = {};

const actions = {
  /**
   * Verifies the authenticity of a user token.
   *
   * @param {Object} context - The Vuex store context.
   * @param {string} token - The user's authentication token.
   * @returns {Promise} - A promise that resolves with the response data.
   */
  async verifyToken(_, token) {
    return new Promise((resolve, reject) => {
      Axios.get(urlMapper.verifyToken, {
        headers: {
          Authtoken: token,
        },
      })
        .then((response) => {
          addLocalStorageItem("Authtoken", response?.data?.Authtoken || token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Performs user login with provided credentials.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User login data, typically containing username and password.
   * @returns {Promise} - A promise that resolves with the login response data.
   */
  login(_, data) {
    return new Promise((resolve, reject) => {
      Axios.post(urlMapper.login, data)
        .then((response) => {
          addLocalStorageItem("Authtoken", response?.data?.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Sends a request for password reset using user's email or username.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User data for password reset, such as email or username.
   * @returns {Promise} - A promise that resolves with the response data.
   */
  forgetPassword(_, data) {
    return new Promise((resolve, reject) => {
      Axios.post(urlMapper.forgetPassword, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Verifies the reset password token for a user.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User data containing a UUID and token for verification.
   * @returns {Promise} - A promise that resolves with the response data.
   */
  verifyResetPasswordToken(_, data) {
    return new Promise((resolve, reject) => {
      const { uuId, token } = data;
      Axios.get(urlMapper.resetPassword + `${uuId}/${token}/`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Resets the user's password using a provided UUID and token.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} data - User data containing a UID, token, and new password.
   * @returns {Promise} - A promise that resolves with the response data.
   */
  resetPassword(_, data) {
    return new Promise((resolve, reject) => {
      const { uid, token } = data;
      Axios.post(urlMapper.resetPassword + `${uid}/${token}/`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
