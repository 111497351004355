<script setup>
import HeaderPagination from "@/components/HeaderPagination.vue";
import Header from "@/components/HeaderComponent.vue";
import { useRoute } from "vue-router";
// import PageFooter from "@/components/PageFooter.vue";

const route = useRoute();
</script>
<template>
  <Header>
    <template v-if="route.meta?.pageHeader" v-slot:header-pagination>
      <HeaderPagination />
    </template>
  </Header>

  <slot />

  <!-- <PageFooter /> -->
</template>
