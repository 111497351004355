import { createWebHistory, createRouter } from "vue-router";
import { isAuthenticated } from "@/utils/functions";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import BlankLayout from "@/layouts/BlankLayout.vue";
import { ROUTE_NAME } from "@/utils/constants";
import { defineAsyncComponent } from "vue";

const LoginPage = defineAsyncComponent(() => import("@/views/LoginPage.vue"));
const ForgetPassword = defineAsyncComponent(() =>
  import("@/views/ForgetPassword.vue")
);
const ResetPassword = defineAsyncComponent(() =>
  import("@/views/ResetPassword.vue")
);
const SignUpPage = defineAsyncComponent(() => import("@/views/SignUpPage.vue"));
const HomePage = defineAsyncComponent(() => import("@/views/HomePage.vue"));
const ResultsPage = defineAsyncComponent(() =>
  import("@/views/ResultsPage.vue")
);
const NextMatchPage = defineAsyncComponent(() =>
  import("@/views/NextMatchPage.vue")
);
const EditProfile = defineAsyncComponent(() =>
  import("@/views/user/EditProfile.vue")
);
const PrizesPage = defineAsyncComponent(() => import("@/views/PrizesPage.vue"));
const RankingPage = defineAsyncComponent(() =>
  import("@/views/RankingPage.vue")
);
const RankingsTable = defineAsyncComponent(() =>
  import("@/views/RankingsTable.vue")
);
const PrivacyPolicy = defineAsyncComponent(() =>
  import("@/views/PrivacyPolicy.vue")
);
const TermsOfService = defineAsyncComponent(() =>
  import("@/views/TermsofService.vue")
);
const ChangePassword = defineAsyncComponent(() =>
  import("@/views/user/ChangePassword.vue")
);

const routes = [
  {
    path: "/login",
    name: ROUTE_NAME.login,
    component: LoginPage,
    meta: { requiresAuth: false, layout: BlankLayout, title: "Login" },
  },
  {
    path: "/forget-password",
    name: ROUTE_NAME.forgetPassword,
    component: ForgetPassword,
    meta: {
      requiresAuth: false,
      layout: BlankLayout,
      title: "Forget Password",
    },
  },
  {
    path: "/reset-password/:uuId/:token",
    name: ROUTE_NAME.resetPassword,
    component: ResetPassword,
    meta: { requiresAuth: false, layout: BlankLayout, title: "Rest Password" },
  },
  {
    path: "/signup",
    name: ROUTE_NAME.signUp,
    component: SignUpPage,
    meta: { requiresAuth: false, layout: BlankLayout, title: "SignUp" },
  },
  {
    path: "/",
    name: ROUTE_NAME.homePage,
    component: HomePage,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: true,
      transition: { name: "", mode: "" },
    },
  },
  {
    path: "/results",
    name: ROUTE_NAME.resultsPage,
    component: ResultsPage,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: true,
      title: "Results",
    },
  },
  {
    path: "/next-matches",
    name: ROUTE_NAME.nextMatchesPage,
    component: NextMatchPage,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: true,
      title: "Next Matches",
    },
  },
  {
    path: "/user/edit-profile",
    name: ROUTE_NAME.userEditProfile,
    component: EditProfile,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      title: "Profile Update",
    },
  },
  {
    path: "/prizes",
    name: ROUTE_NAME.prizesPage,
    component: PrizesPage,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: false,
      title: "Prizes",
    },
  },
  {
    path: "/ranking",
    name: ROUTE_NAME.rankingPage,
    component: RankingPage,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: false,
      title: "Ranking",
    },
  },
  {
    path: "/table",
    name: ROUTE_NAME.rankingsTable,
    component: RankingsTable,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      pageHeader: false,
      title: "Table",
    },
  },
  {
    path: "/privacy-policy",
    name: ROUTE_NAME.privacypolicyPage,
    component: PrivacyPolicy,
    meta: { requiresAuth: false, pageHeader: false, title: "Privacy Policy" },
  },
  {
    path: "/terms-and-conditions",
    name: ROUTE_NAME.termsandconditionsPage,
    component: TermsOfService,
    meta: {
      requiresAuth: false,
      pageHeader: false,
      title: "Terms and Conditions",
    },
  },
  {
    path: "/user/change-password",
    name: ROUTE_NAME.userChangePassword,
    component: ChangePassword,
    meta: {
      requiresAuth: true,
      layout: DefaultLayout,
      title: "Change Password",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to) => {
  const isLoggedIn = isAuthenticated();
  const { requiresAuth, title } = to.meta;

  // Determine the layout dynamically based on whether the user is logged in
  const layout = isLoggedIn ? DefaultLayout : BlankLayout;
  to.meta.layout = layout;

  if (requiresAuth === true && !isLoggedIn) {
    return { name: "login" };
  } else if (
    requiresAuth === false &&
    isLoggedIn &&
    ![ROUTE_NAME.privacypolicyPage, ROUTE_NAME.termsandconditionsPage].includes(
      to.name
    )
  ) {
    return { name: "home-page" };
  }

  // Set the document title based on the route's title property
  document.title = `Liga Benfica${title ? ` - ${title}` : ""}`;

  // Continue with navigation
  return true;
});

export default router;
