/**
 * This Vuex store module manages overall points and current round data.
 * It provides actions to fetch overall points and add a visitor.
 *
 * @module store/points
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {
  overallPoints: null,
  currentRound: null,
};

const actions = {
  /**
   * Fetch overall points from the server.
   *
   * @param {Object} context - The Vuex store context.
   * @returns {Promise} A Promise that resolves with the response data.
   */
  async fetchOverallPoints({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get(urlMapper.overallPoints)
        .then((response) => {
          if (response?.status === 200) {
            commit("setOverallPoints", response.data);
          }
          resolve(response);
        })
        .catch(function (error) {
          console.error("Failed to fetch overall points:");
          reject(error);
        });
    });
  },
  /**
   * Add a visitor to the server.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} visitorData - The data of the visitor to be added.
   * @returns {Promise} A Promise that resolves with the response data.
   */
  addVisitor(_, visitorData) {
    return new Promise((resolve, reject) => {
      Axios.post(urlMapper.visitor, visitorData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error("Failed to add visitor:");
          reject(error);
        });
    });
  },
};

const mutations = {
  /**
   * Set overall points and current round data in the store's state.
   *
   * @param {Object} state - The Vuex store state.
   * @param {Object} data - The data containing overall points and current round.
   */
  setOverallPoints(state, data) {
    state.overallPoints = data.overall_points;
    state.currentRound = data.current_round;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
