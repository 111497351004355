import { createApp } from "vue";
import App from "@/App.vue";
import Vue3TouchEvents from "vue3-touch-events";
import { createI18n } from "vue-i18n";

// PrimeVue
import PrimeVue from "primevue/config";
import "primevue/resources/themes/lara-light-indigo/theme.css"; // Import PrimeVue theme
import "primeflex/primeflex.min.css"; // Import PrimeFlex CSS
import "primevue/resources/primevue.min.css"; // Import PrimeVue core CSS
import "vue-tel-input/vue-tel-input.css"; // Import Vue Tel Input CSS
import VueTelInput from "vue-tel-input";
import Ripple from "primevue/ripple";
import ToastService from "primevue/toastservice";
import store from "@/store";
import router from "@/router";
import filters from "@/utils/filter";
import Hotjar from "@hotjar/browser";

const app = createApp(App);

// Assign filters to global properties of the Vue app
app.config.globalProperties.$filters = filters;

// Configure i18n
export const i18n = createI18n({
  legacy: false,
  locale: "pt",
  fallbackLocale: "en",
  messages: {
    en: require("@/locale/en_uk.json"),
    pt: require("@/locale/pt_pt.json"),
  },
});

const siteId = process.env.VUE_APP_HOTJAR_SITE_ID;
const hotjarVersion = process.env.VUE_APP_HOTJAR_VERSION;

Hotjar.init(siteId, hotjarVersion);

// Use Vue Router, store, and various Vue plugins
app
  .use(router)
  .use(store)
  .use(PrimeVue, { ripple: true }) // Use PrimeVue with Ripple effect
  .directive("ripple", Ripple) // Register Ripple as a directive
  .use(Vue3TouchEvents) // Use Vue3TouchEvents
  .use(i18n) // Use i18n for internationalization
  .use(VueTelInput) // Use Vue Tel Input
  .use(ToastService) // Use ToastService for displaying toasts
  .mount("#app"); // Mount the app to the DOM element with id "app"
