/**
 * Adds an item to the local storage with the specified key and value.
 * @param {string} key - The key under which to store the item.
 * @param {string} value - The value to store.
 * @returns {boolean} - Returns true if the item was successfully added to local storage, or false if local storage is not accessible.
 */
export const addLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    console.error("Not accessible local storage");
    return false;
  }
};

/**
 * Retrieves an item from local storage with the specified key.
 * @param {string} key - The key of the item to retrieve.
 * @returns {string|null} - The value associated with the specified key, or null if local storage is not accessible or the key is not found.
 */
export const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error("Not accessible local storage");
  }
};

/**
 * Removes an item from local storage with the specified key.
 * @param {string} key - The key of the item to remove.
 */
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error("No local storage");
  }
};
