/**
 * This module handles actions related to fetching and managing prize data.
 *
 * @module modules/prizeManagement
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {
  prizes: [],
  prizesCount: 0,
};

const actions = {
  /**
   * Fetches a list of prizes with optional parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Optional parameters for fetching prizes.
   * @returns {Promise} - A promise that resolves with the prize data.
   */
  getPrizesList({ commit }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.prizes}`, { params })
        .then((response) => {
          if (response?.status === 200) {
            commit("setData", {
              key: "prizes",
              payload: response.data.results,
            });
            commit("setData", {
              key: "prizesCount",
              payload: response.data.count,
            });
          }
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

const mutations = {
  /**
   * Sets data in the state based on the provided payload.
   *
   * @param {Object} state - The Vuex state.
   * @param {Object} payload - An object containing the key and payload to update the state.
   */
  setData: (state, payload) => (state[payload.key] = payload.payload),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
