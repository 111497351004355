<script setup>
import { onMounted, ref } from "vue";
import { RouterView } from "vue-router";

// Import custom styles
import "@/assets/css/fonts.scss";
import "@/assets/css/globals.scss";
import "@/assets/css/custom.scss";
import "@/assets/css/color.scss";

// Import Toast component for notifications
import Toast from "primevue/toast";
import { defineAsyncComponent } from "vue";

// Define an asynchronous component for the splash screen
const SplashScreen = defineAsyncComponent(() =>
  import("@/components/SplashScreen.vue")
);

// Create a reactive variable to control the visibility of the splash screen
const showSplashScreen = ref(true);

// Function to hide the splash screen after a delay
const hideSplashScreen = () => {
  setTimeout(() => {
    showSplashScreen.value = false;
  }, 500);
};

// Hide the splash screen when the component is mounted
onMounted(() => hideSplashScreen());
</script>

<template>
  <!-- Conditionally render the appropriate component -->
  <Component v-if="!showSplashScreen" :is="$route.meta.layout">
    <RouterView />
  </Component>

  <!-- Display the splash screen while it's shown -->
  <SplashScreen v-else />

  <!-- Toast component for notifications -->
  <Toast class="custom-p-toast" position="top-center" />
</template>
