/**
 * This module handles various actions related to managing and fetching match data, including match lists,
 * results, next matches, guessed matches, and match guessing.
 *
 * @module modules/matchManagement
 */

import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {
  matchList: [],
  macthCount: 0,
  macthCountWithGuessOfWeek: 0,
  guessedMatchList: [],
  guessedMatchCount: 0,
  matchResults: {},
  nextMatches: {},
  matchRound: 0,
};

const actions = {
  /**
   * Fetches a list of matches with optional parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Optional parameters for fetching matches.
   * @returns {Promise} - A promise that resolves with the match data.
   */
  fetchMatches({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.matches}`, { params })
        .then(function (response) {
          if (!params?.offset || params?.offset === 0) {
            commit("setData", {
              key: "matchList",
              payload: [],
            });
            commit("setData", {
              key: "macthCount",
              payload: 0,
            });
            commit("setData", {
              key: "macthCountWithGuessOfWeek",
              payload: 0,
            });
          }
          const mergeMatchList = [...state.matchList, ...response.data.results];
          commit("setMatchListWithTopGuessOfWeek", {
            key: "matchList",
            list: mergeMatchList,
          });
          commit("setData", {
            key: "macthCount",
            payload: response.data.count,
          });
          commit("setData", {
            key: "matchRound",
            payload: response.data.Round,
          });
          commit("checkMatchCountWithGuessOfWeek", {
            key: "macthCountWithGuessOfWeek",
            count: response.data.count,
            list: mergeMatchList,
          });
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  /**
   * Fetches match results for a specific set of parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Parameters for fetching match results.
   * @returns {Promise} - A promise that resolves with the match results data.
   */
  getMatchResults({ commit }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.results}`, { params })
        .then((response) => {
          if (response?.status === 200) {
            response = { ...response, stateName: "matchResults", params };
            commit("manipulateResultAndNextMatch", response);
          }
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  /**
   * Fetches next matches for a specific set of parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Parameters for fetching next matches.
   * @returns {Promise} - A promise that resolves with the next matches data.
   */
  getNextMatches({ commit }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.nextMatches}`, { params })
        .then((response) => {
          if (response?.status === 200) {
            response = { ...response, stateName: "nextMatches", params };
            commit("manipulateResultAndNextMatch", response);
          }
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  /**
   * Fetches guessed matches and updates the state.
   *
   * @param {Object} context - The Vuex store context.
   * @returns {Promise} - A promise that resolves with the guessed match data.
   */
  getGuessedMatch({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.matchGuess}`)
        .then(function (response) {
          if (response?.status === 200) {
            commit("setData", {
              key: "guessedMatchList",
              payload: response.data.results,
            });
            commit("setData", {
              key: "guessedMatchCount",
              payload: response.data.count,
            });
          }
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  /**
   * Submits a guess for a match.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} payload - Data for the match guess.
   * @returns {Promise} - A promise that resolves upon successful submission.
   */
  guessMatch(_, payload) {
    return new Promise((resolve, reject) => {
      Axios.post(`${urlMapper.matchGuess}`, payload)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

const getters = {
  getRoundsFromResults: (state) => {
    const rounds = state.matchResults?.results;
    return rounds ? Object.keys(rounds) : [];
  },
  getRoundsFromNextMatch: (state) => {
    const rounds = state.nextMatches?.results;
    return rounds ? Object.keys(rounds) : [];
  },
};
const mutations = {
  setData: (state, payload) => (state[payload.key] = payload.payload),

  /* The `manipulateResultAndNextMatch` mutation is responsible for merging the current match results
  or next matches with the existing results in the state. It takes the payload, which contains the
  response data and the name of the state to be updated (either "matchResults" or "nextMatches"). */
  manipulateResultAndNextMatch: (state, payload) => {
    const { stateName } = payload;

    if (payload.params.offset === 0) {
      state[stateName] = payload?.data;
    } else {
      const prevMatchResults = state?.[stateName]?.results;
      const currentMatchResults = payload?.data?.results || {};
      const mergedResult = { ...prevMatchResults };

      /* The code block is iterating over the keys of the `currentMatchResults` object. For each key,
      it checks if the key already exists in the `mergedResult` object. If it does, it concatenates
      the values of the key in both objects using the `concat` method. If the key does not exist in
      the `mergedResult` object, it assigns the value of the key from the `currentMatchResults`
      object to the `mergedResult` object. Essentially, this code is merging the values of the same
      keys from both objects into a single object. */
      for (const key in currentMatchResults) {
        mergedResult[key] = mergedResult?.[key]
          ? mergedResult[key].concat(currentMatchResults[key])
          : currentMatchResults[key];
      }

      state[stateName] = { ...payload.data, results: mergedResult };
    }
  },
  checkMatchCountWithGuessOfWeek: (state, payload) => {
    const { key, count, list } = payload;

    const guessOfWeekMatch = list.reduce(
      (total, currentValue) =>
        currentValue?.marketselection?.is_guess_of_week ? ++total : total,
      0
    );

    state[key] = count + guessOfWeekMatch * 2;
  },
  setMatchListWithTopGuessOfWeek: (state, payload) => {
    const { key, list } = payload;

    const listWithTopGuessOfWeekMatch = list.reduce(
      (allCard, currentValue, currentIndex, arr) => {
        /* The code block is iterating over an array of objects (`list`) and categorizing each object
      into two groups: "blackCard" and "normalCard". Then merge both array so guess of week (Black card) card show in list of top ) */
        if (currentValue?.marketselection?.is_guess_of_week) {
          allCard.blackCard.push(currentValue);
        } else {
          allCard.normalCard.push(currentValue);
        }

        if (currentIndex == arr.length - 1) {
          allCard = [...allCard.blackCard, ...allCard.normalCard];
        }

        return allCard;
      },
      { blackCard: [], normalCard: [] }
    );

    state[key] = listWithTopGuessOfWeekMatch;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
