import { DateTime } from "luxon";

/**
 * Custom filters for date and time manipulation.
 */
const filters = {
  /**
   * Calculates the difference in milliseconds between the given date and the current date.
   * @param {string} value - The date to calculate the difference from, in ISO format.
   * @returns {number} - The difference in milliseconds.
   */
  getDateTimeInMs(value) {
    const today = DateTime.now();
    const someday = DateTime.fromISO(value);
    const diff = someday.diff(today, ["milliseconds"]).milliseconds;

    return diff;
  },

  /**
   * Formats the given date in ISO format to "dd/MM" format.
   * @param {string} value - The date to format in ISO format.
   * @returns {string} - The formatted date in "dd/MM" format or an empty string if the input date is invalid.
   */
  getDateWithFormate(value) {
    try {
      const date = DateTime.fromISO(value);
      return date.toFormat("dd/MM");
    } catch {
      return "";
    }
  },

  /**
   * Formats the given time in ISO format to "HH:mm" format.
   * @param {string} value - The time to format in ISO format.
   * @returns {string} - The formatted time in "HH:mm" format or an empty string if the input time is invalid.
   */
  getTimeWithFormate(value) {
    try {
      const time = DateTime.fromISO(value);
      return time.toFormat("HH:mm");
    } catch {
      return "";
    }
  },
};

export default filters;
