<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const macthCountWithGuessOfWeek = computed(
  () => store.state.matches.macthCountWithGuessOfWeek
);
const guessedMatchList = computed(() => store.state.matches.guessedMatchList);
const guessedMatchCount = computed(() => store.state.matches.guessedMatchCount);

// calculates the total points for a match using computed.
const getTotalPoints = computed(() =>
  guessedMatchList.value.reduce(function (previousValue, currentValue) {
    let result = previousValue + currentValue.selectionPoints;
    return result;
  }, 0)
);

// calculates the total euro = odds * 5 = 5odds
const getTotalEuro = computed(() =>
  guessedMatchList.value.reduce(function (previousValue, currentValue) {
    let result = previousValue + currentValue.selectionPrice;
    return result;
  }, 0)
);
</script>
<template>
  <div class="header-notification">
    <div class="container">
      <div class="header-notification-row">
        <div class="header-notification-left">
          <h2>
            <span>
              {{ guessedMatchCount }}/{{ macthCountWithGuessOfWeek }}
            </span>
            {{ $t("HomePage.guesses") }}
          </h2>
          <h3>
            <img src="@/assets/images/fire.png" alt="fire" />
            {{ $t("HomePage.possible_points", { points: getTotalPoints }) }}
          </h3>
        </div>
        <div class="header-notification-right">
          <div class="notification-right-text">
            <p>
              <span class="green-text"
                >{{ (getTotalEuro * 5).toFixed(2) }}€</span
              >
              {{ $t("HomePage.if_betting") }}
            </p>
          </div>
          <div class="notification-right-icon">
            <img
              src="@/assets/images/liga-betano-black.svg"
              alt="liga betano black"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
