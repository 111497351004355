export const isAuthenticated = () => {
  return localStorage.getItem("Authtoken") !== null;
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const postalCodeRegExp = /(^\d{4}$)|(^\d{4}-\d{3}$)/;

export const dayRegExp = /0[1-9]|[12][0-9]|3[01]/;
export const monthRegExp = /0[1-9]|1[0,1,2]/;

export const yearRegExp = /^(19|20)[0-9]{2}$/;
