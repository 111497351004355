import axios from "axios";
import router from "@/router";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "@/utils/localStorage";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    Accept: "application/json",
  },
});

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const Authtoken = getLocalStorageItem("Authtoken");
    if (Authtoken) {
      config.headers.Authorization = `Token ${Authtoken}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  (response) => {
    try {
      if (axios.isCancel(response)) {
        return new Promise(() => {});
      } else {
        return response;
      }
    } catch (response) {
      console.log(response);
    }
  },
  (error) => {
    try {
      if (axios.isCancel(error)) {
        return new Promise(() => {});
      }
      if (error.response && error.response.status === 401) {
        removeLocalStorageItem("Authtoken");
        router.push("/login");
      }
      return Promise.reject(error);
    } catch (error) {
      console.log(error);
    }
  }
);

export default Axios;
