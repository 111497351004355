import { createStore } from "vuex";
import login from "@/store/login";
import signup from "@/store/signup";
import league from "@/store/league";
import matches from "@/store/matches";
import prizes from "@/store/prizes";
import ranking from "@/store/ranking";
import user from "@/store/user";
import common from "./common";
import rankingstable from "./rankingstable";

const store = createStore({
  modules: {
    login,
    signup,
    league,
    matches,
    prizes,
    ranking,
    user,
    common,
    rankingstable,
  },
});

export default store;
