/**
 * This module handles actions related to fetching and managing ranking data, including the ranking list,
 * the count of rankings, and information about the logged-in user.
 *
 * @module modules/rankingManagement
 */
import Axios from "@/services/axios";
import urlMapper from "@/urlMapper";

const state = {
  rankingList: [],
  rankingCount: 0,
  loggedInUser: {},
};

const actions = {
  /**
   * Fetches a list of rankings with optional parameters.
   *
   * @param {Object} context - The Vuex store context.
   * @param {Object} params - Optional parameters for fetching rankings.
   * @returns {Promise} - A promise that resolves with the ranking data.
   */
  getRankingList({ commit }, params) {
    return new Promise((resolve, reject) => {
      Axios.get(`${urlMapper.ranking}`, { params })
        .then((response) => {
          commit("setData", {
            key: "rankingList",
            payload: response.data.results,
          });
          commit("setData", {
            key: "rankingCount",
            payload: response.data.count,
          });
          commit("setData", {
            key: "loggedInUser",
            payload: response.data.logged_user,
          });
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};

const mutations = {
  /**
   * Sets data in the state based on the provided payload.
   *
   * @param {Object} state - The Vuex state.
   * @param {Object} payload - An object containing the key and payload to update the state.
   */
  setData: (state, payload) => (state[payload.key] = payload.payload),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
