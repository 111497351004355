export const MATCH_RESULT = "Match Result";
export const OVER_UNDER_TOTAL_GOALS = "Over/Under Total Goals";
export const DOUBLE_CHANCE = "Double Chance";
export const BOTH_TEAMS_SCORE = "Both Teams to Score";

export const NEXTMATCHES = "Next Matches";
export const RESULTS = "Results";
export const GUESSES = "Guesses";

export const ROUTE_NAME = {
  login: "login",
  forgetPassword: "forgetPassword",
  resetPassword: "resetPassword",
  signUp: "signUp",
  homePage: "home-page",
  prizesPage: "prizes",
  rankingPage: "ranking",
  resultsPage: "resultsPage",
  nextMatchesPage: "nextMatchesPage",
  userEditProfile: "userEditProfile",
  rankingsTable: "table",
  privacypolicyPage: "privacy-policy",
  termsandconditionsPage: "terms-and-conditions",
  userChangePassword: "change-password",
};

export const ERROR_PAGE_MAPPER = {
  403: "accessDenied",
  404: "pageNotFound",
  500: "serverErrorPage",
};

export const PRIZES_RANK = {
  1: "1st",
  2: "2nd",
  3: "3rd",
};
