// Define the base domain and base URL for API requests
const BASE_DOMAIN = process.env.VUE_APP_API_HOST;
const BASE_URL = "api/v1/";

// URL Mapper object with API endpoints
const urlMapper = {
  BASE_DOMAIN: BASE_DOMAIN,
  BASE_URL: BASE_URL,

  // Authentication endpoints
  verifyToken: `${BASE_URL}verify-token/`,
  login: `${BASE_URL}login/`,
  register: `${BASE_URL}register/`,
  forgetPassword: `${BASE_URL}forget-password/`,
  resetPassword: `${BASE_URL}reset-password/`,
  logout: `${BASE_URL}logout/`,

  // Data endpoints
  cities: `${BASE_URL}cities/`,
  leagues: `${BASE_URL}leagues/`,
  matches: `${BASE_URL}matches/`,

  // Game-related endpoints
  results: `${BASE_URL}results/`,
  nextMatches: `${BASE_URL}nextgames/`,
  matchGuess: `${BASE_URL}match_guess/`,

  // Prize-related endpoint
  prizes: `${BASE_URL}prizes/`,

  // Ranking and profile endpoints
  ranking: `${BASE_URL}ranking/`,
  userProfile: `${BASE_URL}profile/`,
  overallPoints: `${BASE_URL}overall_points/`,
  rankingsTable: `${BASE_URL}standings/`,
  changepassword: `${BASE_URL}change-password/`,
  visitor: `${BASE_URL}visitor/`,
};

export default urlMapper;
