<script setup>
import Button from "primevue/button";
import { getLocalStorageItem } from "@/utils/localStorage";
import HeaderNotificationBar from "./HeaderNotificationBar.vue";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";

const store = useStore();
const toast = useToast();
const router = useRouter();

const matchRound = computed(() => store.state.matches.matchRound);
const overallPoints = computed(() => store.state.common.overallPoints);
const current_round = computed(() => store.state.common.currentRound);

const token = getLocalStorageItem("Authtoken");

const logout = () => {
  store.dispatch("user/logout").then(() => {
    toast.add({
      severity: "success",
      summary: "You have been successfully logged out",
      detail: "",
      life: 2000,
    });
    router.push("/login");
  });
};

onMounted(() => {
  if (token && matchRound.value === 0) {
    if (router.currentRoute.value.path !== "/") {
      store.dispatch("matches/getGuessedMatch");

      // In order to control homepage skeleton loading, the fetchMatches API was called in the HomePage instead of HeaderComponent.
      store.dispatch("matches/fetchMatches", { offset: 0, limit: 30 });
    }

    store.dispatch("common/fetchOverallPoints");
  }
});

const openBetanoLink = async () => {
  // Define the visitor data or body here
  const visitorData = {
    url: "https://gml-grp.com/C.ashx?btag=a_32534b_2885c_&affid=8048&siteid=32534&adid=2885&c=",
  };

  try {
    // Dispatch the addVisitor action to call the API and update the store
    await store.dispatch("common/addVisitor", visitorData);

    // Open the Betano link in a new tab
    window.open(
      "https://gml-grp.com/C.ashx?btag=a_32534b_2885c_&affid=8048&siteid=32534&adid=2885&c=",
      "_blank"
    );
  } catch (error) {
    console.error("Error calling addVisitor action:");
  }
};
</script>

<template>
  <header>
    <div class="header header-v-2">
      <div class="container">
        <div class="header-inner">
          <div class="header-left">
            <div class="header-left-text">
              <div class="highlight-text">
                <h2><span class="highlight-text-light">LIGA</span> BENFICA</h2>
              </div>
              <div v-if="token" class="round-number">
                <span>{{ $t("HomePage.round") }} {{ current_round }} </span>
              </div>
            </div>
          </div>
          <div class="header-right">
            <div v-if="token" class="logout" @click="logout()" v-show="false">
              <span>Logout</span>
            </div>
            <div
              v-if="['/', '/login', '/signup'].includes($route.path)"
              class="header-right-logo"
            >
              <a href="#" @click.prevent="openBetanoLink">
                <div class="betano-logo">
                  <img
                    src="@/assets/images/betano-logo-new.webp"
                    alt="liga betano black"
                    width="85"
                    height="33"
                  />
                  <!-- <span>betano.pt</span> -->
                </div>
              </a>
              <div class="header-right-note">
                <p>{{ $t("Logo.do_your_bet_real_time") }}</p>
              </div>
            </div>
            <div v-else class="header-right-points">
              <span>{{ $t("Labels.over_all_ponits") }}:</span>
              <h2>
                {{ overallPoints || 0 }}
                <img src="@/assets/images/fire.png" alt="fire" class="fire" />
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- login page -->
  <div v-if="!token" class="header-bottom login-header-bottom">
    <div class="header-notification">
      <div class="container">
        <div class="header-notification-row">
          <div class="header-notification-left">
            <h2>
              {{ $t("Login.welcome_message") }}
            </h2>
          </div>
          <div class="header-notification-right header-notification-btn">
            <div class="signup-btn">
              <RouterLink to="/signup">
                <Button class="primary-btn">{{ $t("Labels.sign_up") }}</Button>
              </RouterLink>
            </div>
            <div class="login-btn">
              <RouterLink to="/login">
                <Button class="secondary-btn">{{ $t("Login.log_in") }}</Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-else>
    <div class="header-bottom">
      <HeaderNotificationBar />
      <slot name="header-pagination"></slot>
    </div>
  </template>
</template>

<style lang="scss">
@use "@/assets/css/header.scss";
</style>
