/**
 * This module handles the state, actions, and mutations related to leagues.
 *
 * @module modules/leagues
 */

import axios from "axios";
import urlMapper from "@/urlMapper";

const state = {
  leagues: [],
};

const actions = {
  /**
   * Fetches a list of leagues and updates the state.
   *
   * @param {Object} context - The Vuex store context.
   * @returns {Promise} - A promise that resolves with the response data.
   */
  async fetchLeagues({ commit }) {
    try {
      const response = await axios.get(urlMapper.leagues);
      commit("setLeagues", response.data);
      return response;
    } catch (error) {
      console.error("Failed to fetch leagues:");
    }
  },
};

const mutations = {
  /**
   * Sets the leagues data in the state.
   *
   * @param {Object} state - The Vuex state.
   * @param {Array} leagues - An array of league data to set in the state.
   */
  setLeagues(state, leagues) {
    state.leagues = leagues;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
